/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useState, useEffect } from "react";
import DataGridButton from "../../components/DataGridButton";
import { Box, Grid, Switch, Typography, useTheme } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { UserContext } from "../../contexts";
import { tokens } from "../../theme";
import DetailPanelContent from "./DetailPanelContent";
import Notification from "../../components/Notification";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import MultiSelect from "../../components/MultiSelect";
import {
  useGetVulnerabilities,
  useGetAllVulnerabilityFilterOptions,
  usePatchVulnerability,
} from "../../api/vulnerabilities";
import {
  createQueryString,
  handleAdminister,
  handleExcludeForAll,
  handleRequestApproval,
  updateSelectedItemsInFilter,
} from "./utils";
import { adaptVulnerabilities } from "./adapter";
import { showNotification, toTitleCase } from "../../utils/helperFunctions";
import { USER_ROLES, VULNERABILITY_STATUS } from "../../utils/constants";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";
import { packageVulnerabilityStyles as styles } from "./styles";
import { Close, Done } from "@mui/icons-material";

const PackageVulnerabilities = ({ vulnerabilityType, header }) => {
  const loggedInUser = useContext(UserContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [vulnerabilities, setVulnerabilities] = useState([]);
  const [filters, setFilters] = useState({});
  const [selectedItemsInFilter, setSelectedItemsInFilter] = useState({});
  const [isUserCodeVulnerabilityReviewer, setIsUserCodeVulnerabilityReviewer] =
    useState(false);

  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    title: "",
    content: "",
    handleDialogConfirm: () => {},
  });

  const resetDialog = () => {
    setConfirmationDialog({
      isOpen: false,
      title: "",
      content: "",
      handleDialogConfirm: () => {},
    });
  };

  const {
    postData: getVulnerabilities,
    response: getVulnerabilitiesResp,
    isLoading: isLoadingVulnerabilities,
  } = useGetVulnerabilities(vulnerabilityType);

  const {
    postData: patchVulnerability,
    response: patchVulnerabilityResp,
    responseStatus: patchVulnerabilityStatus,
  } = usePatchVulnerability();

  const {
    postData: getAllVulnerabilityFilterOptions,
    response: getAllVulnerabilityFilterOptionsResp,
  } = useGetAllVulnerabilityFilterOptions(vulnerabilityType);

  useEffect(() => {
    getAllVulnerabilityFilterOptions();
  }, []);
  useEffect(() => {
    setIsUserCodeVulnerabilityReviewer(
      loggedInUser?.data?.roles?.includes(
        USER_ROLES.CODE_VULNERABILITY_REVIEWERS
      )
    );
  }, [loggedInUser?.data]);

  useEffect(() => {
    if (Array.isArray(getVulnerabilitiesResp?.response)) {
      setVulnerabilities(adaptVulnerabilities(getVulnerabilitiesResp.response));
    }
  }, [getVulnerabilitiesResp]);

  useEffect(() => {
    if (getAllVulnerabilityFilterOptionsResp?.response) {
      setFilters(getAllVulnerabilityFilterOptionsResp.response);
    }
  }, [getAllVulnerabilityFilterOptionsResp]);

  useEffect(() => {
    getVulnerabilities(createQueryString(selectedItemsInFilter));
  }, [selectedItemsInFilter]);

  // Notifications
  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const notificationCallback = () => {
    getVulnerabilities(createQueryString(selectedItemsInFilter));
    getAllVulnerabilityFilterOptions();
  };
  const showNotificationWrapper = (resp, respStatus, callback = () => {}) => {
    showNotification(
      resp,
      respStatus,
      callback,
      setMessage,
      setSeverity,
      setNotify
    );
  };

  useEffect(() => {
    showNotificationWrapper(
      patchVulnerabilityResp,
      patchVulnerabilityStatus,
      notificationCallback
    );
  }, [patchVulnerabilityResp, patchVulnerabilityStatus]);

  const getDetailPanelHeight = useCallback(() => "auto", []);

  const columns = [
    {
      field: "repository",
      renderHeader: () => <Typography variant="h5">Repository Name</Typography>,
      flex: 2,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { repository } }) => (
        <Typography variant="h5">{repository || ""}</Typography>
      ),
    },
    {
      field: "vulnerabilityId",
      flex: 1.75,
      renderHeader: () => (
        <Typography variant="h5">Vulnerability Id</Typography>
      ),
      renderCell: ({ row: { vulnerabilityId } }) => (
        <Typography variant="h5">{vulnerabilityId ?? ""}</Typography>
      ),
    },
    {
      field: "packageName",
      flex: 1.75,
      renderHeader: () => <Typography variant="h5">Package Name</Typography>,
      renderCell: ({ row: { packageName } }) => (
        <Typography variant="h5">{packageName ?? ""}</Typography>
      ),
    },
    {
      field: "status",
      flex: 1.5,
      renderHeader: () => <Typography variant="h5">Status</Typography>,
      renderCell: ({ row: { status } }) => (
        <Typography variant="h5">{status ?? ""}</Typography>
      ),
    },
    {
      field: "excludedForAll",
      flex: 1,
      renderHeader: () => <Typography variant="h5">Exclude All</Typography>,
      renderCell: ({ row }) => (
        <Switch
          color="secondary"
          checked={row?.excludedForAll}
          onChange={() =>
            handleExcludeForAll(row, setConfirmationDialog, patchVulnerability)
          }
          size="small"
          disabled={!isUserCodeVulnerabilityReviewer}
        />
      ),
    },
    vulnerabilityType === "cves" && {
      field: "fixAvailable",
      flex: 1,
      renderHeader: () => <Typography variant="h5">Fix Available</Typography>,
      renderCell: ({ row: { fixAvailable } }) => (
        <Typography variant="h5">
          {fixAvailable ? (
            <Done sx={styles.fixAvailableIconStyles(fixAvailable)} />
          ) : (
            <Close sx={styles.fixAvailableIconStyles(fixAvailable)} />
          )}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: ({ row }) =>
        [
          isUserCodeVulnerabilityReviewer &&
            row.status !== VULNERABILITY_STATUS.APPROVED && (
              <GridActionsCellItem
                label="Approve"
                onClick={() =>
                  handleAdminister(
                    row,
                    "Approve",
                    setConfirmationDialog,
                    patchVulnerability
                  )
                }
                showInMenu
              />
            ),
          isUserCodeVulnerabilityReviewer &&
            row.status !== VULNERABILITY_STATUS.REJECTED && (
              <GridActionsCellItem
                label="Reject"
                onClick={() =>
                  handleAdminister(
                    row,
                    "Reject",
                    setConfirmationDialog,
                    patchVulnerability
                  )
                }
                showInMenu
              />
            ),
          [VULNERABILITY_STATUS.NEW, VULNERABILITY_STATUS.REJECTED].includes(
            row?.status
          ) && (
            <GridActionsCellItem
              label="Request Approval"
              onClick={() =>
                handleRequestApproval(
                  row,
                  setConfirmationDialog,
                  patchVulnerability
                )
              }
              showInMenu
            />
          ),
        ].filter(Boolean),
    },
  ].filter(Boolean);

  return (
    <Box sx={tabbedDataGridPage.tabContentMainBox}>
      <Notification
        notify={notify}
        severity={severity}
        setNotify={setNotify}
        message={message}
      />
      <ConfirmationDialog
        isOpen={confirmationDialog.isOpen}
        title={confirmationDialog.title}
        handleDialogConfirm={confirmationDialog.handleDialogConfirm}
        content={confirmationDialog.content}
        resetDialog={resetDialog}
      />
      <Grid sx={tabbedDataGridPage.gridContainer} container columns={2}>
        <Grid xs={1}>{header}</Grid>
        <Grid xs={1}>
          <Box sx={tabbedDataGridPage.topButtonStack}>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() =>
                getVulnerabilities(createQueryString(selectedItemsInFilter))
              }
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
          </Box>
        </Grid>
      </Grid>
      <Box sx={styles.multiSelectStack}>
        {Object.keys(filters).map((filterName) => {
          return (
            <MultiSelect
              allItems={filters[filterName].map((item) => item.toString())}
              setSelectedItems={updateSelectedItemsInFilter(
                setSelectedItemsInFilter,
                filterName
              )}
              label={toTitleCase(filterName)}
              limit={1}
              sx={styles.multiSelect.sx}
              textFieldProps={styles.multiSelect.textFieldProps}
              chipSx={styles.multiSelect.chipSx}
              populateItemsWhenEmpty={false}
            />
          );
        })}
      </Box>
      <Box sx={styles.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={({ row }) => <DetailPanelContent row={row} />}
          getRowId={(row) => row?.id}
          loading={isLoadingVulnerabilities}
          rows={vulnerabilities}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default PackageVulnerabilities;
