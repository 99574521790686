/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Select, Stack, Typography } from "@mui/material";
import ConvoyDialog from "../../../../components/ConvoyDialog";
import { useEffect, useState } from "react";
import { useGetLabelsToRefresh } from "../../../../api/envs";

const SimpleRefresh = ({
  env,
  selectedRefreshBase,
  setSelectedRefreshBase,
  setRefreshType,
  resetDialog,
  refreshEnv,
}) => {
  const [value, setValue] = useState(selectedRefreshBase);
  const [labels, setLabels] = useState([]);

  const { postData: getLabels, response: getLabelsResp } =
    useGetLabelsToRefresh();

  useEffect(() => {
    getLabels(`?deploymentName=${env?.name}`);
  }, [env]);

  useEffect(() => {
    if (Array.isArray(getLabelsResp?.response)) {
      setLabels(getLabelsResp?.response);
    }
  }, [getLabelsResp]);

  useEffect(() => {
    setSelectedRefreshBase(value);
  }, [value]);

  const handleDialogConfirm = () => {
    refreshEnv({
      url: env?.url,
      releaseLabel: selectedRefreshBase,
    });
    resetDialog();
  };
  return (
    <ConvoyDialog
      open={true}
      title="Refresh environment"
      handleDialogConfirm={handleDialogConfirm}
      resetDialog={resetDialog}
      additionalActions={[
        {
          buttonText: "UPDATE UI ONLY",
          onClick: () => setRefreshType("ui"),
        },
        {
          buttonText: "CUSTOM REFRESH",
          onClick: () => setRefreshType("custom"),
        },
      ]}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography component="span" variant="h5">
          You are going to refresh the environment '{env?.name}' to
        </Typography>
        <Select
          autoFocus
          size="small"
          value={selectedRefreshBase}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        >
          {labels?.map((label, i) => (
            <MenuItem value={label} key={i}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </ConvoyDialog>
  );
};

export default SimpleRefresh;
