import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { Edit } from "@mui/icons-material";
import DataGridButton from "../../../components/DataGridButton";
import { red, green, orange, brown } from "@mui/material/colors";
import { simpleCardStyle } from "../../../styles/cardStyles";
import styles from "../styles";
import { dataGridButtonTypography } from "../../../styles/DataGridStyles";
import { stackCenterGap } from "../../../styles/utilStyles";
import { dataGridButtonStyles } from "../../../styles/buttonStyles";
import { DISABLED_PATCH_RELEASE_STATUS } from "../../../utils/constants";
import useIamModal from "../../IamModal/UseIamModal";
import { useHasPermission } from "../../../api/iam";

const getProperties = () => {
  return {
    UNDER_VALIDATION: {
      color: orange[800],
    },
    ARCHIVED: {
      color: brown[700],
    },
    IN_PRODUCTION: {
      color: green[700],
    },
    REJECTED: {
      color: red[800],
    },
    DELETED: {
      color: brown[700],
    },
  };
};

const orderedStatus = [
  "UNDER_VALIDATION",
  "IN_PRODUCTION",
  "REJECTED",
  "ARCHIVED",
  "DELETED",
];

const ReleasesCard = ({ label, handlePatchLabel, handleUpdateStatus, app }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const canCreatePatch = useHasPermission(
    `/apps/${app}/releases/${label?.release_label}/patches`,
    "POST",
    "CREATE"
  );

  const { IamModal, openIamModal, canViewIamModal } = useIamModal(
    `/apps/${app}/releases/${label?.release_label}/patches`
  );

  return (
    <Box sx={simpleCardStyle(colors)}>
      {IamModal}
      <Box sx={styles.releaseCardHeadingStack}>
        <Typography variant="h3">
          Release &nbsp;{label?.patches?.[0]?.patch_label?.slice(4)}
        </Typography>
        <Box sx={stackCenterGap} />
        {canViewIamModal ? (
          <DataGridButton
            sx={dataGridButtonStyles.patchButton}
            onClick={(event) => openIamModal()}
          >
            <Typography sx={dataGridButtonTypography(colors)}>IAM</Typography>
          </DataGridButton>
        ) : (
          <></>
        )}
        <DataGridButton
          sx={dataGridButtonStyles.patchButton}
          disabled={DISABLED_PATCH_RELEASE_STATUS.includes(label?.status)}
          onClick={(event) => {
            event.stopPropagation();
            if (label?.release_label) {
              handlePatchLabel(label.release_label, canCreatePatch);
            }
          }}
        >
          <Typography sx={dataGridButtonTypography(colors)}>
            {!canCreatePatch && "PREVIEW"} PATCH
          </Typography>
        </DataGridButton>
      </Box>
      <Divider />
      <Box sx={styles.cardSummaryItemStyle}>
        <Typography variant="h4" sx={styles.stageNameStyle}>
          Status
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography
          variant="h5"
          sx={styles.stageStatusStyle(colors, getProperties, label?.status)}
        >
          {label?.status || "UNASSIGNED"}
        </Typography>
        {useHasPermission(
          `/apps/${app}/releases/${label}/patches`,
          "PATCH",
          "UPDATE_PARENT_STATUS"
        ) && (
          <IconButton
            onClick={() => {
              if (label?.release_label) {
                handleUpdateStatus(
                  label.release_label,
                  label?.status,
                  orderedStatus.slice(orderedStatus.indexOf(label?.status))
                );
              }
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ReleasesCard;
