import { useState } from "react";
import { Modal } from "@mui/material";
import utilColors from "../styles/utilColors";

const styles = {
  iframe: {
    background: utilColors.white,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "80vh",
  },
};

export const ReportModal = ({ modal, setModal }) => {
  return (
    <Modal
      open={modal?.isOpen}
      onClose={() => setModal({ isOpen: false })}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <iframe title="report" src={modal?.url} style={styles.iframe} />
    </Modal>
  );
};

export const useReportModal = () => {
  const [modal, setModal] = useState({ isOpen: false });

  return {
    reportModal: <ReportModal modal={modal} setModal={setModal} />,
    setReportModalOpen: (url) => {
      setModal({ isOpen: true, url });
    },
  };
};
