import { Box, Typography, Checkbox } from "@mui/material";
import { githubUrl } from "../../../utils/urls";
import RedirectLink from "../../../components/RedirectLink";
import styles from "../styles";
import { stackCenterGap } from "../../../styles/utilStyles";

const CommitsList = ({
  commits,
  repoName,
  commitsNotIncluded,
  setCommitsNotIncluded,
  readOnly,
}) => {
  const handleCheckBoxClick = (i) => {
    setCommitsNotIncluded((prev) => {
      const curr = structuredClone(prev);
      i < prev[repoName] ? (curr[repoName] = i) : (curr[repoName] = i + 1);
      return curr;
    });
  };

  return (
    <>
      {commits?.map((commit, i) => {
        return (
          <Box sx={styles.commitListItemStack} key={i}>
            {readOnly && (
              <Checkbox
                checked={i >= commitsNotIncluded[repoName]}
                color="secondary"
                size="small"
                onChange={() => handleCheckBoxClick(i)}
              />
            )}
            <Typography component="span" variant="h5">
              {commit?.commit_tag}&nbsp;&nbsp;&nbsp;|
            </Typography>
            <RedirectLink
              href={`${githubUrl}/${repoName}/commit/${commit?.commit_hash}`}
            >
              {commit?.commit_message}
            </RedirectLink>
            <Box sx={stackCenterGap} />
            <Typography sx={styles.buildStatus(commit?.build_status)}>
              {commit?.build_status}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

export default CommitsList;
