import { RMO_IMAGE_COMPONENTS } from "../../../../utils/constants";
import { removeDuplicates } from "../../../../utils/helperFunctions";

// TODO might need to add changes when we bring back artifacts
export const getReleaseManifestOverride = (
  updatedReleaseManifest,
  originalReleaseManifest,
  additionalReleaseManifest
) => {
  return Object.keys(updatedReleaseManifest).reduce((rmo, artifactType) => {
    const artifacts = Object.keys(updatedReleaseManifest[artifactType]).reduce(
      (accumulator, service) => {
        const u = updatedReleaseManifest[artifactType][service];
        const o = originalReleaseManifest[artifactType][service];
        const curr = {};
        if (u?.name !== o?.name) {
          curr.name = u?.name;
        }
        if (u?.tag !== o?.tag) {
          curr.tag = u?.tag;
        }
        if (u?.repository !== o?.repository) {
          curr.repository = u?.repository;
        }
        if (Object.keys(curr).length) {
          return { ...accumulator, [service]: curr };
        }
        return accumulator;
      },
      {}
    );

    const additionalRMO = additionalReleaseManifest[artifactType]?.reduce(
      (tot, curr) => {
        const { key, ...rest } = curr;
        return key ? { ...tot, [key]: rest } : tot;
      },
      {}
    );

    const artifactClass = { ...artifacts, ...additionalRMO };
    if (Object.keys(artifactClass).length) {
      return { ...rmo, [artifactType]: artifactClass };
    }
    return rmo;
  }, {});
};

export const getListOfServices = (releaseManifest) => {
  return removeDuplicates(
    Object.keys(releaseManifest)
      ?.map((artifactType) => {
        return Object.keys(releaseManifest[artifactType]).reduce(
          (tot, service) => {
            tot.push(service);
            tot.push(releaseManifest[artifactType][service]?.name);
            return tot;
          },
          []
        );
      })
      .flat()
  ).sort();
};

export const detectChange = (
  field,
  orignal,
  updated,
  artifactType,
  service
) => {
  const o = orignal[artifactType][service];
  const u = updated[artifactType][service];
  switch (field) {
    case "repository":
      return o?.repository !== u?.repository;
    case "name":
      return u?.name !== o?.name;
    case "tag":
      return u?.valueFrom === "tag" ? o?.tag !== u?.tag : o?.s3Url !== u?.s3Url;
    case "any":
      return RMO_IMAGE_COMPONENTS.reduce(
        (prev, curr) =>
          prev || detectChange(curr, orignal, updated, artifactType, service),
        false
      );
    default:
      return true;
  }
};
