/* eslint-disable react-hooks/exhaustive-deps */
import { iamUrl } from "../utils/urls";
import { useLazyFetch, useLazyPost, getHeaders } from "../utils/apiclient";
import { useContext, useEffect } from "react";
import { UserContext } from "../contexts";

export const useCreateGroup = (path) => {
  return useLazyPost(`${iamUrl}${path}/_iam_/groups`, {
    headers: { ...getHeaders(), Action: "CREATE" },
    method: "POST",
  });
};

export const useCreateRole = (path) => {
  return useLazyPost(`${iamUrl}${path}/_iam_/roles`, {
    headers: { ...getHeaders(), Action: "CREATE" },
    method: "POST",
  });
};

export const useDeleteGroup = (path) => {
  return useLazyFetch(`${iamUrl}${path}/_iam_/groups`, {
    headers: { ...getHeaders(), Action: "DELETE" },
    method: "DELETE",
  });
};

export const useDeleteRole = (path) => {
  return useLazyFetch(`${iamUrl}${path}/_iam_/roles`, {
    headers: { ...getHeaders(), Action: "DELETE" },
    method: "DELETE",
  });
};

export const useListAllGroups = (path) => {
  return useLazyFetch(`${iamUrl}${path}/_iam_/groups`, {
    headers: { ...getHeaders(), Action: "LIST_ALL" },
  });
};

export const useListAllRoles = (path) => {
  return useLazyFetch(`${iamUrl}${path}/_iam_/roles`, {
    headers: { ...getHeaders(), Action: "LIST_ALL" },
  });
};

export const useReadGroup = (path) => {
  return useLazyFetch(`${iamUrl}${path}/_iam_/groups`, {
    headers: { ...getHeaders(), Action: "READ" },
  });
};

export const useReadRole = (path) => {
  return useLazyFetch(`${iamUrl}${path}/_iam_/roles`, {
    headers: { ...getHeaders(), Action: "READ" },
  });
};

export const useUpdateGroupMembers = (path) => {
  return useLazyPost(`${iamUrl}${path}/_iam_/groups`, {
    headers: { ...getHeaders(), Action: "UPDATE_MEMBERS" },
    method: "PATCH",
  });
};

export const useUpdateRoleAssignments = (path) => {
  return useLazyPost(`${iamUrl}${path}/_iam_/roles`, {
    headers: { ...getHeaders(), Action: "UPDATE_ASSIGNMENTS" },
    method: "PATCH",
  });
};

export const useUpdateRolePermissions = (path) => {
  return useLazyPost(`${iamUrl}${path}/_iam_/roles`, {
    headers: { ...getHeaders(), Action: "UPDATE_PERMISSIONS" },
    method: "PATCH",
  });
};

export const useHasPermission = (path, method, action, dependencies = []) => {
  const loggedInUser = useContext(UserContext);
  const { postData, responseStatus } = useLazyPost(`${iamUrl}/http-requests`, {
    headers: { ...getHeaders(), Action: "CHECK_AUTHORIZATION" },
    method: "POST",
  });
  useEffect(() => {
    postData({
      path,
      method,
      action,
      user: loggedInUser?.email,
    });
  }, dependencies);
  return responseStatus === 200;
};
