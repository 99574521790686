/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
} from "@mui/material";
import { USER_ROLES } from "../../utils/constants";
import styles from "./styles";

const Argo = () => {
  const loggedInUser = useContext(UserContext);

  const [tabValue, setTabValue] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedView, setSelectedView] = useState(0);

  const data = [
    loggedInUser?.data?.roles?.includes(USER_ROLES.DEVOPS_TEAM) && {
      tabName: "argo-devtest",
      items: [
        {
          name: "Argo-Workflows",
          url: "https://api.convoy.tessell.cloud/argo-wf",
        },
        {
          name: "Argo-CD",
          url: "https://api.convoy.tessell.cloud/argo-cd",
        },
      ],
    },
    {
      tabName: "argo-devtest2",
      items: [
        {
          name: "Argo-Workflows",
          url: "https://api.convoy.tessell.cloud/argo-wf2",
        },
        {
          name: "Argo-CD",
          url: "https://api.convoy.tessell.cloud/argo-cd2",
        },
      ],
    },
  ].filter(Boolean);

  useEffect(() => {
    setTabValue(selectedTab);
  }, [selectedTab]);

  const tabs = data.map((tab, i) => {
    return <Tab key={i} label={tab.tabName} value={i} />;
  });

  const tabContent = data.map((tab, i) => {
    return (
      <TabPanel value={i} key={i}>
        <Box sx={styles.formControlBox}>
          <FormControl>
            <InputLabel size="string" id="select-label">
              App
            </InputLabel>
            <Select
              labelId="select-view-label"
              id="select-view"
              value={selectedView}
              label="App"
              sx={styles.appSelect}
              onChange={(e) => setSelectedView(e.target.value)}
            >
              {tab.items.map((view, i) => (
                <MenuItem value={i} key={i}>
                  {view.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <iframe
          title="argo"
          src={tab.items[selectedView].url}
          style={styles.iframe}
        />
      </TabPanel>
    );
  });
  return (
    <>
      <TabContext value={tabValue}>
        <TabList
          onChange={(e, newValue) => setSelectedTab(newValue)}
          textColor="secondary"
          indicatorColor="secondary"
          sx={styles.tabList}
        >
          {tabs}
        </TabList>
        {tabContent}
      </TabContext>
    </>
  );
};

export default Argo;
