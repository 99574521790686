import Box from "@mui/material/Box";
import { UI_PROXY_CONTEXT } from "../../utils/constants";
import { grafanaUrl } from "../../utils/urls";
import { styles } from "./styles";

const contextUrlMap = {
  [UI_PROXY_CONTEXT.logs]: grafanaUrl,
};

const UiProxy = ({ context }) => {
  return (
    <Box sx={styles.mainBox}>
      <iframe title="ui" src={contextUrlMap[context]} style={styles.iframe} />
    </Box>
  );
};

export default UiProxy;
