/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ConvoyDialog from "../../../../components/ConvoyDialog";
import {
  useHasPermission,
  useReadGroup,
  useReadRole,
  useUpdateGroupMembers,
  useUpdateRoleAssignments,
} from "../../../../api/iam";
import { assigneesDialogStyles as styles } from "../styles";
import UsersList from "./UsersList";
import GroupsList from "./GroupsList";
import { IAM_ENTITY } from "../../../../utils/constants";

const useUpdateAssignees = (entity, path) => {
  const groupObj = useUpdateGroupMembers(path);
  const roleObj = useUpdateRoleAssignments(path);
  switch (entity) {
    case IAM_ENTITY.GROUP:
      return groupObj;
    case IAM_ENTITY.ROLE:
      return roleObj;
    default:
      return {};
  }
};

const useReadEntity = (entity, path) => {
  const groupObj = useReadGroup(path);
  const roleObj = useReadRole(path);
  switch (entity) {
    case IAM_ENTITY.GROUP:
      return groupObj;
    case IAM_ENTITY.ROLE:
      return roleObj;
    default:
      return { postData: () => {} };
  }
};

const MemebersDialog = ({
  dialogProps,
  setDialogProps,
  path,
  showNotificationWrapper,
}) => {
  const [currUsers, setCurrUsers] = useState([]);
  const [currGroups, setCurrGroups] = useState([]);
  const [dialogPropsCopy, setDialogPropsCopy] = useState({});

  const { postData: readEntity, response: readEntityResp } = useReadEntity(
    dialogPropsCopy?.entity,
    path
  );

  const canUpdateAssignees = useHasPermission(
    `${path}/_iam_/${dialogProps?.entity}s/${dialogProps?.entityName}`,
    "PATCH",
    dialogProps?.entity === IAM_ENTITY.GROUP
      ? "UPDATE_MEMBERS"
      : "UPDATE_ASSIGNMENTS",
    [dialogProps]
  );

  const {
    postData: updateAssignees,
    response: updateAssigneesResp,
    responseStatus: updateAssigneesStatus,
  } = useUpdateAssignees(dialogPropsCopy?.entity, path);

  useEffect(() => {
    if (dialogProps?.entity) {
      setDialogPropsCopy(dialogProps);
    }
    if (!dialogProps?.isOpen) {
      setCurrUsers([]);
      setCurrGroups([]);
    }
  }, [dialogProps]);

  useEffect(() => {
    if (
      dialogProps?.entity &&
      dialogProps?.isOpen &&
      dialogProps?.entity === dialogPropsCopy?.entity
    ) {
      readEntity(`/${dialogProps?.entityName}`);
    }
  }, [dialogProps, dialogPropsCopy]);

  useEffect(() => {
    if (readEntityResp?.users?.length) {
      setCurrUsers(readEntityResp?.users);
    }
    if (readEntityResp?.groups?.length) {
      setCurrGroups(readEntityResp?.groups?.map((group) => group?.id));
    }
  }, [readEntityResp]);

  useEffect(() => {
    showNotificationWrapper(
      updateAssigneesResp,
      updateAssigneesStatus,
      () => {}
    );
  }, [updateAssigneesResp, updateAssigneesStatus]);

  const handleDialogConfirm = () => {
    const payload = { users: currUsers };
    if (dialogProps?.entity === IAM_ENTITY.ROLE) {
      payload.groups = currGroups;
    }

    updateAssignees(payload, `/${dialogProps?.entityName}`);
    resetDialog();
  };

  const resetDialog = () => {
    setDialogProps({
      isOpen: false,
      entity: "",
    });
  };

  return (
    <ConvoyDialog
      open={dialogProps?.isOpen}
      title={`${dialogProps?.entityName} assignees`}
      handleDialogConfirm={handleDialogConfirm}
      resetDialog={resetDialog}
      onCancelClick={resetDialog}
      confirmBtnText={"UPDATE"}
      cancelBtnText={"CLOSE"}
    >
      <Box sx={styles.mainBox}>
        <UsersList
          currUsers={currUsers}
          setCurrUsers={setCurrUsers}
          canUpdateAssignees={canUpdateAssignees}
        />
        {dialogProps?.entity === IAM_ENTITY.ROLE ? (
          <GroupsList
            currGroupIds={currGroups}
            setCurrGroupIds={setCurrGroups}
            path={path}
            canUpdateAssignees={canUpdateAssignees}
          />
        ) : (
          <></>
        )}
      </Box>
    </ConvoyDialog>
  );
};

export default MemebersDialog;
