// case transform --------
/**
 * @param {string} str
 * @returns {string[]} */
export const splitWords = (str) =>
  (str.toUpperCase() === str ? str : str.replace(/([A-Z])/g, " $1"))
    .replace(/(_)/g, " ")
    .split(" ")
    .filter(Boolean);

/**
 * @param {string} str
 * @returns {string} */
export const toTitleCase = (str) =>
  splitWords(str)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(" ");

/**
 * @param {string} str
 * @returns {string} */
export const toPascalCase = (str) => toTitleCase(str).replaceAll(" ", "");

/**
 * @param {string} str
 * @returns {string} */
export const toCamelCase = (str) =>
  str.charAt(0).toLowerCase() + toPascalCase(str).slice(1);

/**
 * @param {string} str
 * @returns {string} */
export const toSnakeCase = (str) => splitWords(str).join("_").toLowerCase();

/**
 * @param {string} str
 * @returns {string} */
export const toKebabCase = (str) => splitWords(str).join("-").toLowerCase();

// ---------------------
/**
 * @param {string} jsonString
 * @returns {boolean} */
export const isValidJSON = (jsonString) => {
  try {
    const obj = JSON.parse(jsonString);
    if (obj && typeof obj === "object") {
      return true;
    }
  } catch (e) {}
  return false;
};

/**
 * @param {Array.<any>} arr
 * @returns {Array.<any>} */
export const removeDuplicates = (arr) => {
  return [...new Set(arr)];
};

// --------------------
//use json.strinfy and typeof
/**
 * @param {any} resp
 * @returns {string} */
export const extractMessageFromResponse = (resp) =>
  resp?.message || resp?.detail?.message || resp?.detail?.msg || resp?.detail;

/**
 * @param {any} resp
 * @param {number} respStatus
 * @param {function} successCallback
 * @param {function} setMessage
 * @param {function} setSeverity
 * @param {function} setNotify
 * @returns
 */
export const showNotification = (
  resp,
  respStatus,
  successCallback,
  setMessage,
  setSeverity,
  setNotify
) => {
  const message = extractMessageFromResponse(resp);
  if (message?.length && typeof respStatus === "number") {
    setMessage(message);
    setSeverity(respStatus < 300 ? "success" : "error");
    setNotify(true);
    if (respStatus < 300) {
      successCallback();
    }
  }
};

/**
 * @param {any} url
 * @returns  */
export const downloadFileFromUrl = (url) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
