import { Box, CircularProgress } from "@mui/material";

const styles = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    backgroundColor: "rgba(18, 18, 18, 0.9)",
  },
};

const LoadingOverlay = () => {
  return (
    <Box sx={styles.mainBox}>
      <CircularProgress color="secondary" />
    </Box>
  );
};
export default LoadingOverlay;
