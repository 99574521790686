import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { yellow, cyan } from "@mui/material/colors";
import { toPascalCase } from "../../utils/helperFunctions";
import { dataGridFooterStatusStyles } from "../../styles/DataGridStyles";
import utilColors from "../../styles/utilColors";

export const statusEnum = [
  "RUNNING",
  "HIBERNATED",
  "FAILED",
  "OTHERS",
  "TOTAL",
];

export function CustomEnvFooter({ statusCounts }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const statusColor = (status) => {
    const colorMap = {
      RUNNING: utilColors.oliveGreen,
      HIBERNATED: yellow[800],
      FAILED: utilColors.bloodRed,
      OTHERS: colors.grey[500],
      TOTAL: cyan[700],
    };
    return colorMap[status] || colors.primary[800];
  };
  return (
    <Box
      sx={{
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        backgroundColor: colors.primary[700],
        p: 1,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {statusEnum.map((status, i) => {
        const statusTypography = (
          <Typography
            key={i}
            sx={dataGridFooterStatusStyles(statusColor(status))}
          >
            {toPascalCase(status)}: {statusCounts[status] || 0}
          </Typography>
        );
        return statusCounts[`${status}List`] ? (
          <Tooltip
            key={i}
            title={
              <>
                {Object.keys(statusCounts[`${status}List`]).map(
                  (subStatus, i) => {
                    return (
                      <Typography key={i}>
                        {toPascalCase(subStatus)}:{" "}
                        {statusCounts[`${status}List`][subStatus]}
                      </Typography>
                    );
                  }
                )}
              </>
            }
          >
            {statusTypography}
          </Tooltip>
        ) : (
          statusTypography
        );
      })}
    </Box>
  );
}
