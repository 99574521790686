/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { UserContext } from "../../../contexts";
import Select from "../../../components/Select";
import MultiSelect from "../../../components/MultiSelect";
import { TextFieldWithHelperText } from "../../../components/TextFieldWithHelperText";
import { toCamelCase } from "../../../utils/helperFunctions";
import {
  CONTROL_PLANE_SERVICE_PLAN,
  CONTROL_PLANE_CLOUD_TYPE,
  GLOBAL_ENVIRONMENT_NAME,
  DATA_PLANE_CLOUD_TYPE,
} from "../../../utils/constants";
import { useGetLabelsToCreate } from "../../../api/envs";
import { requestCPDialogStyles } from "../styles";

const RequestCPDialogContent = ({ requestCPPayload, setRequestCPPayload }) => {
  const loggedInUser = useContext(UserContext);
  useEffect(() => {
    setRequestCPPayload({
      approverId: loggedInUser?.data?.quotas?.controlPlane?.allocated
        ? loggedInUser?.data?.id
        : loggedInUser?.data?.manager?.id,
      projectName: "",
      releaseLabel: "",
      justification: "",
      globalEnvironmentName: "",
      servicePlan: CONTROL_PLANE_SERVICE_PLAN[0],
      cloudType: "",
    });
  }, []);

  const { postData: getLabels, response: getLabelsResp } =
    useGetLabelsToCreate();

  useEffect(() => {
    getLabels();
  }, []);

  const textFieldCommonProps = (label) => ({
    fullWidth: true,
    label: label,
    color: "secondary",
    value: requestCPPayload?.[toCamelCase(label)],
    onChange: (e) => {
      setRequestCPPayload((prev) => ({
        ...prev,
        [toCamelCase(label)]: e.target.value,
      }));
    },
  });

  return (
    <>
      <Grid container spacing={2} sx={requestCPDialogStyles.gridContainer}>
        <Grid item xs={3}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Project Name")}
            onChange={(e) => {
              /^[a-z0-9]*$/.test(e.target.value) &&
                setRequestCPPayload((prev) => ({
                  ...prev,
                  projectName: e.target.value,
                }));
            }}
            helperText="allowed chars: [a-z0-9]"
          />
        </Grid>
        <Grid item xs={3}>
          <Select {...textFieldCommonProps("Release Label")}>
            {getLabelsResp?.response?.map((label, i) => (
              <MenuItem value={label} key={i}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select {...textFieldCommonProps("Global Environment Name")}>
            {GLOBAL_ENVIRONMENT_NAME.map((globalEnv, i) => (
              <MenuItem value={globalEnv} key={i}>
                {globalEnv}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <TextField
            {...textFieldCommonProps("Manager")}
            defaultValue={loggedInUser?.data?.manager?.email}
            disabled
          />
        </Grid>
        <Grid item xs={9}>
          <TextField
            {...textFieldCommonProps("Justification")}
            multiline
            maxRows={2}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Jira Id")}
            helperText="Feature/Epic/Bug"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            defaultValue={CONTROL_PLANE_SERVICE_PLAN[0]}
            {...textFieldCommonProps("Service Plan")}
          >
            {CONTROL_PLANE_SERVICE_PLAN.map((plan, i) => (
              <MenuItem value={plan} key={i}>
                {plan}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            {...textFieldCommonProps("Cloud Type")}
            label="Control Plane Cloud"
          >
            {CONTROL_PLANE_CLOUD_TYPE.map((cloud, i) => (
              <MenuItem value={cloud} key={i}>
                {cloud}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <MultiSelect
            label="Data Plane Clouds"
            setSelectedItems={(newVal) => {
              setRequestCPPayload((prev) => ({
                ...prev,
                dataPlaneClouds: newVal,
              }));
            }}
            limit={1}
            sx={requestCPDialogStyles.multiSelect}
            populateItemsWhenEmpty={false}
            allItems={DATA_PLANE_CLOUD_TYPE}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RequestCPDialogContent;
