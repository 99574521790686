/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { useState } from "react";

/**
 * @param {string} url
 * @param {Object} options
 * @returns
 */
export function useLazyFetch(url, options) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);

  const postData = async (paramsString = "") => {
    setIsLoading(true);
    try {
      const res = await fetch(`${url}${paramsString}`, {
        credentials: "include",
        ...options,
      });
      setResponseStatus(res?.status);
      const json = await res?.json();

      setResponse(json);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  return {
    postData,
    response,
    responseStatus,
    error,
    isLoading,
  };
}

/**
 * @param {string} url
 * @param {Object} options
 * @returns
 */
export function useLazyPost(url, options) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);

  const postData = async (body, paramsString = "") => {
    setIsLoading(true);
    try {
      const res = await fetch(`${url}${paramsString}`, {
        credentials: "include",
        ...options,
        body: JSON.stringify(body),
      });
      setResponseStatus(res?.status || null);
      const json = await res?.json();

      setResponse(json);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  return {
    postData,
    response,
    responseStatus,
    error,
    isLoading,
  };
}

export const getHeaders = () => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return headers;
};
