import { commonDataGridStyles } from "../../styles/DataGridStyles";

const styles = {
  dataGridBoxStyles: (colors) => ({
    ...commonDataGridStyles(colors),
    height: "85vh",
    mt: "-12px",
    width: "99.4%",
  }),
  viewSelectForm: {
    width: "25%",
    mt: "5px",
    ml: "2px",
    paddingX: "5px",
  },
  viewSelectSelect: {
    height: "35px",
  },
  reportButton: {
    width: "80px",
  },
};
export default styles;
