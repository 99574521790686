import { useLazyFetch } from "../utils/apiclient";
import { devopsUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const codeCoverageUrl = `${devopsUrl}/code/coverage`;

export const useGetCodeCoverageData = (appGroup, releaseLabel) => {
  return useLazyFetch(
    `${codeCoverageUrl}/app-groups/${appGroup}/release-labels/${releaseLabel}`,
    {
      headers: getHeaders(),
    }
  );
};
