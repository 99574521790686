export const styles = {
  resultStack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
    margin: "auto",
  },
  triggerDialogLoading: { mx: "250px" },
  textFieldStack: { width: "500px", mt: "5px" },
};
