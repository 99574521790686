/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ConvoyDialog from "../../../../components/ConvoyDialog";
import {
  useHasPermission,
  useReadRole,
  useUpdateRolePermissions,
} from "../../../../api/iam";
import { permissionsDialogStyles as styles } from "../styles";
import PermissionsList from "./PermissionsList";

const PermissionsDialog = ({
  dialogProps,
  setDialogProps,
  path,
  showNotificationWrapper,
}) => {
  const [currPermissions, setCurrPermissions] = useState([]);

  const canUpdatePermissions = useHasPermission(
    `${path}/_iam_/roles/${dialogProps?.roleName}`,
    "PATCH",
    "UPDATE_PERMISSIONS",
    [dialogProps]
  );

  const { postData: readRole, response: readRoleResp } = useReadRole(path);

  const {
    postData: updatePermissions,
    response: updatePermissionsResp,
    responseStatus: updatePermissionsStatus,
  } = useUpdateRolePermissions(path);

  useEffect(() => {
    if (dialogProps?.isOpen) {
      readRole(`/${dialogProps?.roleName}`);
    }
  }, [dialogProps]);

  useEffect(() => {
    if (readRoleResp?.permissions?.length) {
      setCurrPermissions(readRoleResp?.permissions);
    }
  }, [readRoleResp]);

  useEffect(() => {
    showNotificationWrapper(
      updatePermissionsResp,
      updatePermissionsStatus,
      () => {}
    );
  }, [updatePermissionsResp, updatePermissionsStatus]);

  const handleDialogConfirm = () => {
    updatePermissions(
      { permissions: currPermissions },
      `/${dialogProps?.roleName}`
    );
    resetDialog();
  };

  const resetDialog = () => {
    setDialogProps({
      isOpen: false,
    });
    setCurrPermissions([]);
  };

  return (
    <ConvoyDialog
      open={dialogProps?.isOpen}
      title={`${dialogProps?.roleName} permissions`}
      handleDialogConfirm={handleDialogConfirm}
      resetDialog={resetDialog}
      onCancelClick={resetDialog}
      confirmBtnText={"UPDATE"}
      cancelBtnText={"CLOSE"}
    >
      <Box sx={styles.mainBox}>
        <PermissionsList
          currPermissions={currPermissions}
          setCurrPermissions={setCurrPermissions}
          canUpdatePermissions={canUpdatePermissions}
        />
      </Box>
    </ConvoyDialog>
  );
};

export default PermissionsDialog;
